<template>
  <div v-for="(lounges,eventId) in events" :key="eventId" class="mt-16">
    <div class="relative group pb-2">
      <h2 class="relative text-white text-3xl md:text-4xl text-center font-semibold z-[10] inline-block">
        <span class="absolute left-0 bottom-1 bg-primary/60 h-2 w-full rounded-lg z-[-1]"></span>
        {{ lounges[0].eventTitle }}
      </h2>
      - {{ new Date(lounges[0].startTime).toLocaleDateString() }}
    </div>
    <div v-for="(lounge, index) in lounges" :key="index" class="rounded-lg bg bg-gray-950 pb-1 mb-8">
      <div class="relative">
        <div class="w-full max-h-80 min-h-40 flex items-center justify-center overflow-hidden">
          <img :src="lounge.image?`${config.projectURL}assets/images/lounges/${lounge.image}.jpg`:'/assets/images/events/party.jpeg'" class="w-full">
        </div>
        <div v-if="lounge.quantity - lounge.reserved" class="hidden md:block group absolute w-4/5 md:w-3/6 text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-60 px-5 py-3 rounded-lg hover:bg-[#E3000B] cursor-pointer transition"
             @mouseover="lounge.hover = true"
             @mouseout="lounge.hover = false"
             @click="openLounge(lounge)"
        >
          <button class="uppercase" >
            {{ lounge.hover ? 'Jetzt buchen' : lounge.title }}
          </button>
        </div>
        <div v-else class="hidden md:block group absolute w-4/5 md:w-3/6 text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-60 px-5 py-3 rounded-lg hover:bg-[#E3000B] cursor-pointer transition">
          <button class="uppercase" >
            Ausverkauft
          </button>
        </div>
        <div class="md:hidden group absolute w-4/5 md:w-3/6 text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-60 px-5 py-3 rounded-lg" @click="openLounge(lounge)">
          <button class="uppercase" >
            {{lounge.title}}
          </button>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-x-1 md:gap-x-2 py-4 md:py-4 md:px-4">
        <div class="cols-sp-1 text-center md:hover:bg-[#E3000B] rounded-lg cursor-pointer md:py-2 transition">
          <h5 class="text-xl">{{ lounge.maxGuests }}</h5>
          <h6 class="text-gray-400">Personen</h6>
        </div>
        <div class="cols-sp-1 text-center md:hover:bg-[#E3000B] rounded-lg cursor-pointer md:py-2 transition">
          <h5 class="text-xl">{{ lounge.price }} €</h5>
          <h6 class="text-gray-400">Mindestverzehr</h6>
        </div>
        <div class="cols-sp-1 text-center md:hover:bg-[#E3000B] rounded-lg cursor-pointer md:py-2 transition">
          <h5 class="text-xl">= {{ (parseInt(lounge.price) / parseInt(lounge.maxGuests)).toFixed(2)}} €</h5>
          <h6 class="text-gray-400">pro Person</h6>
        </div>
      </div>
      <div class="px-4">
        <button class="md:hidden w-full text-center px-5 py-3 rounded-lg bg-[#E3000B] cursor-pointer transition my-4 transition uppercase" @click="openLounge(lounge)">
          Jetzt buchen
        </button>
      </div>
    </div>
  </div>
  <VueModal :show="checkOutModal" @close="checkOutModal = false" min-size="small" max-size="large">
    <check-out :item="selectedLounge" checkout-type="lounges" />
  </VueModal>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import CheckOut from "@/components/payment/checkOut.vue";
import {aSyncData} from "@/modules/main";
import event from "@/components/home/event.vue";

export default {
  name: "LoungesView",
  components: {CheckOut},
  props: ["lounge"],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      checkOutModal: false,
      selectedLounge: null,
      loungeList: [],
      events:{}
    }
  },
  mounted() {
    this.getLounges()
  },
  methods:{
    openLounge(l){
      this.selectedLounge = l
      this.checkOutModal = true
    },
    getLounges(){
      aSyncData('lounge/getAll')
          .then(r => {
            if(r.status === 250){
              this.loungeList = r.data
              this.sortedLounges()
            }
          })
          .catch(e => {})
    },
    sortLoungesByEventId() {
      return this.loungeList.sort((a, b) => a.eventId - b.eventId);
    },
    sortedLounges() {
      const sortedLounges = this.sortLoungesByEventId();
      this.events = {}; // Zurücksetzen des `months`-Objekts
      for (const lounge of sortedLounges) {
        const eventId = lounge.eventId;
        if (!this.events[eventId]) {
          this.events[eventId] = [];
        }
        this.events[eventId].push(lounge);
      }
    },
  },

}
</script>