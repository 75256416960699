<template>
  <header>
    <div :class="['fixed top-0 left-0 w-full transition-all duration-300', { 'bg-black/80': isScrolled }]">
      <div id="desktopHeader" class="hidden md:grid grid-12 grid-cols-12 grid-rows-1 items-center mx-8 py-4">
        <div class="col-sp-2">
          <router-link to="/"><img src="/assets/images/logo-light.png" alt="" class="width-150"></router-link>
        </div>
        <div class="nav max-w-screen-xl mx-auto flex gap-x-4 col-sp-9">
          <router-link v-for="(nav, index) in desktopNavigation" :key="index" :to="nav.link">
            <button
                    class="px-4 py-1 border-2 border-solid rounded-full hover:border-[#E3000B] text-md hover:text-[#E3000B] transition">
              {{ nav.title }}
            </button>
          </router-link>
        </div>
        <div class="col-sp-1 text-right" v-if="auth.isAuth">
          <popover class="relative">
            <popover-button>
              <img v-if="!desktopModal" @click="desktopModal=true"
                   src="/assets/images/icons/menu-icon-purple.svg"
                   class="w-12 h-12 p-2 border-4 border-solid rounded-full border-[#E3000B] transition">
              <img v-else @click="desktopModal = false" src="assets/images/icons/close-icon-purple.svg"
                   class="w-10 h-10 menubtn p-2 border-4 border-solid rounded-full border-[#E3000B] transition">
            </popover-button>
            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1"
                        enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
                        leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute right-0 z-10 mt-5 flex w-screen max-w-xs ">
                <div
                    class="w-screen max-w-xs h-80 overflow-y-auto flex-auto overflow-hidden rounded-3xl bg-bgPrimary text-sm leading-6 shadow-md shadow-primary ring-1 ring-gray-900/5">
                  <div class="p-4">
                    <div v-for="item in desktopNavigationLogin" :key="item.title"
                         class="group relative flex w-fit gap-x-6 rounded-lg p-4 ">
                      <div>
                        <router-link :to="item.link" class="font-semibold text-white relative transition-all group-hover:pl-3 duration-300 text-lg">
                          {{ item.title }}
                        <span class="absolute ml-0 rounded-lg -bottom-0.5 left-0 h-1 bg-primary group-hover:w-full group-hover:ml-3 w-0 transition-all duration-300"/>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </popover>
        </div>
        <div class="col-sp-1 text-right" v-else>
          <router-link to="login">
          <button
              class="px-6 py-1 border-2 border-solid rounded-full border-[#E3000B] text-lg text-[#E3000B] hover:font-semibold duration-300 transition">
            Login
          </button>
          </router-link>
        </div>
      </div>
    </div>
    <div id="mobileHeader" class="md:hidden px-4 pt-[calc(env(safe-area-inset-top)+4px)] absolute top-0 left-0 w-full transition-all duration-300"
         :class="{ 'bg-black/80 pb-6': isScrolled }">
      <div class="grid grid-cols-3 items-center">
        <div class="col-s-1">
          <router-link to="/"><img src="assets/images/logo-light.png" alt=""></router-link>
        </div>
        <div class="col-sp-2 flex justify-end gap-x-4 items-center">
          <router-link to="login">
          <button v-if="!auth.isAuth && !config.menuModal"
              class="px-6 py-1 border-4 border-solid rounded-full border-[#E3000B] text-xl text-[#E3000B] duration-300 hover:font-semibold transition">
            Login
          </button>
          </router-link>
          <img v-if="!config.menuModal" @click="config.menuModal = true; supportModal = false;"
               src="assets/images/icons/menu-icon-purple.svg"
               class="w-12 h-12 p-2 border-4 border-solid rounded-full border-primary transition">
        </div>
      </div>
      <TransitionRoot as="template" :show="config.menuModal">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="config.menuModal = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80" />
          </TransitionChild>

          <div class="fixed inset-0 flex pt-[env(safe-area-inset-top)]">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-1 border-2 border-primary border-solid focus-visible:outline-none rounded-lg" @click="config.menuModal = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-8 w-8 text-primary stroke-2" aria-hidden="true" />
                    </button>
                  </div>
                </TransitionChild>
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-bgPrimary px-6 pb-2 ring-1 ring-primary/20">
                  <div class="flex h-16 shrink-0 items-center">
<!--                    <img class="h-8 w-auto" src="/assets/images/logo-light.png" :alt="config.projectName" />-->
                  </div>
                  <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in desktopNavigation" :key="item.title">
                            <router-link :to="item.link" :class="[item.link === $route.path ? 'text-primary text-lg' : 'text-white hover:text-primary text-sm', 'group flex gap-x-3 rounded-md p-2  leading-6 font-semibold']">
                              <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                              {{ item.title }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <div class="relative">
                        <div class="absolute inset-0 flex items-center" aria-hidden="true">
                          <div class="w-full border-t border-primary border-px border-solid" />
                        </div>
                        <div class="relative flex justify-center">
                          <span class="bg-primary px-3 text-base font-semibold leading-6 rounded-md text-white">Persönliches</span>
                        </div>
                      </div>
                      <li v-if="auth.isAuth">
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                          <li v-for="item in desktopNavigationLogin" :key="item.title">
                            <router-link :to="item.link" :class="[item.link === $route.path ? 'text-primary text-lg' : 'text-white hover:text-primary text-sm', 'group flex gap-x-3 rounded-md p-2  leading-6 font-semibold']">
                              <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                              {{ item.title }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li v-else class="mx-auto">
                        <button
                                class="px-6 py-1 border-4 border-solid rounded-full border-primary text-xl text-primary hover:font-semibold transition">
                          <router-link to="login">Login</router-link>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </div>

  </header>
<!--  <transition name="modal" id="MenuModal" style="position: fixed; padding: 16px;">-->
<!--    <div class="" v-if="config.menuModal">-->
<!--      <div class="vueModalOuter">-->
<!--        <div class="w-full max-w-sm m-auto pt-4 mt-8 w-48 grid">-->
<!--          <button v-for="(nav, index) in desktopNavigation" :key="index"-->
<!--                  class="text-left text-gray-200 text-xl hover:text-[#E3000B] transition mb-4">-->
<!--            <router-link :to="nav.link">{{ nav.title }}</router-link>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </transition>-->

<!--  <transition name="modal" id="SupportModal">-->
<!--    <div class="vueModal" v-if="supportModal">-->
<!--      <div class="vueModalOuter" style="position: relative;">-->
<!--        <div class="vueModalInner largeMax" id="supportChat">-->
<!--          <section class="pad-16 t-center">-->
<!--            <h3 style="padding-left: 24px;" class="f-clear">-->
<!--              Support-Chat-->
<!--              <div class="f-right" @click="supportModal = 0; supportStep = 0;">-->
<!--                <img style="cursor: pointer;" src="assets/images/icons/close-icon.svg" class="width-24">-->
<!--              </div>-->
<!--            </h3>-->
<!--          </section>-->
<!--          <section id="chat">-->
<!--            <div v-for="chat in chatArray" :key="chat.question" style="display: inline;">-->
<!--              <div @click="()=>{pushToChat(chat.answers)}" v-html="chat.question" style="display: inline;"/>-->
<!--            </div>-->
<!--          </section>-->
<!--          <section id="chatFooter">-->
<!--            <div>-->
<!--              <div class="field" v-if="user.email === ''">-->
<!--                <input type="email" v-model="email" placeholder="Deine E-Mail-Adresse">-->
<!--              </div>-->
<!--              <div class="field">-->
<!--                <textarea name="" v-model="chatMessage" cols="30" rows="3"-->
<!--                          placeholder="Du hast weitere Fragen? Dann schreibe hier direkt eine Nachricht..."/>-->
<!--              </div>-->
<!--              <div>-->
<!--                <button class="button bgcolor-blue color-white fluid" v-if="chatMessage !== '' && email !== ''"-->
<!--                        @click="sendMessage()">-->
<!--                  Absenden-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </section>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </transition>-->
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {nextTick} from 'vue';
import {questions} from "@/data/questions";
import {notificationStore} from "@/store/notificationStore";
import {Popover, PopoverButton, PopoverPanel,Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {XMarkIcon} from "@heroicons/vue/24/outline";

export default {
  name: "NavBar",
  components: {
    Popover, PopoverButton, PopoverPanel,Dialog, DialogPanel, TransitionChild, TransitionRoot,XMarkIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notification = notificationStore()
    const user = userStore()

    return {auth, config, notification, user}
  },
  data() {
    return {
      supportModal: false,
      notificationCount: 0,
      navCityItems: '',
      chatArray: [],
      chatStep: 0,
      questionObj: questions,
      chatMessage: '',
      email: this.user.email,
      isScrolled: false,
      desktopModal:false,
      desktopNavigation: [
        {
          title: 'Home',
          link: '/',
        },
        {
          title: 'Events & Tickets',
          link: '/events',
        },
        {
          title: 'Fotos & Media',
          link: '/media',
        },
        {
          title: 'Lounges',
          link: '/lounges',
        },
        {
          title: 'FAQ',
          link: '/faq',
        },
        {
          title: 'Jobs',
          link: '/jobs',
        },
        // {
        //   title: 'Kontakt',
        //   link: '/contact',
        // }
      ],
      desktopNavigationLogin: [
        // {
        //   title: 'Meine Events',
        //   link: '/myEvents',
        // },
        {
          title: 'Meine Tickets',
          link: '/tickets',
        },
        {
          title: 'Guthaben',
          link: '/wallet',
        },
        {
          title: 'Prämienshop',
          link: '/coupons',
        },
        {
          title: 'Garderobe',
          link: '/garderobe',
        },
        {
          title: 'Taxiruf',
          link: '/taxiruf',
        },
        {
          title: 'Fundgrube',
          link: '/fundgrube',
        },
        {
          title: 'Feedback',
          link: '/feedback',
        },
        {
          title: 'Scoreboard',
          link: '/scoreboard',
        },
        {
          title: 'Muttizettel',
          link: '/muttizettel',
        },
        {
          title: 'Ticket nicht erhalten',
          link: '/lostticket',
        },
        {
          title: 'Einstellungen',
          link: '/settings',
        },
        {
          title: 'Profil',
          link: '/profile',
        }
      ]
    }
  },
  mounted() {
    this.getNotificationsCount()
    this.getMyCities()
    window.addEventListener('scroll', this.handleScroll);
    this.questionObj.map(singleObject => (
        this.chatArray.push(singleObject)
    ))
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 10;
    },
    async pushToChat(arr) {
      arr.map(singleArr => (
          this.chatArray.push(singleArr)
      ))
      await nextTick()
      let scroll_to_bottom = document.getElementById('chat');
      scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    },
    sendMessage() {
      const fD = new FormData()
      fD.append('email', this.email)
      fD.append('chatMessage', this.chatMessage)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('home/sendMessage', fD)
          .then((response) => {
            if (response.status === 250) {
              const Messagearray = [{
                question: '<div><span class="label">' + this.chatMessage + '</span></div>',
                answers: []
              }];
              this.pushToChat(Messagearray)
              const Responsearray = [{
                question: '<div><span class="label blue">Deine Nachricht wurde erfolgreich versendet. Wir werden uns umgehend bei dir via E-Mail melden.</span></div>',
                answers: []
              }];
              this.pushToChat(Responsearray)
              this.chatMessage = '';
            }
          })
    },
    getNotificationsCount() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNotificationsCount', fD)
          .then((response) => {
            if (response.status === 250) {
              _this.notificationCount = response.data.length
            }
          })
    },
    readNotifications() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/readNotifications', fD)
          .then((response) => {
            if (response.status === 250) {
              _this.notificationCount = 0
            }
          })
    },
    getMyCities() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('limit', 6)
      this.axios.post('home/getMyCities', fD)
          .then((response) => {
            if (response.status === 250) {
              _this.navCityItems = response.data
            }
          })
    },
  }
}
</script>

<style scoped>
header {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  padding-top: calc(8px + env(safe-area-inset-top));
}


header img.image {
  border: 2px solid #ffffff;
}

header .menubtn {
  background-color: var(--second-bg-color);
  border-radius: 8px;
  padding: 4px;
  cursor: pointer;
}

header .menubtn img {
  filter: invert(1);
}

#MenuModal {
  top: 40px;
  height: 100%;
  background: #000000;
  z-index: 60;
  padding-top: calc(16px + env(safe-area-inset-top)) !important;
}

#MenuModal .menuContent #navTop {
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
}

#MenuModal .menuContent .locations h4 {
  margin-top: 16px;
  font-size: 1.3em;
  color: #2068ff;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 16px;
}

#MenuModal .menuContent .locations {
  padding: 0;
  margin: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 16px;
}

#MenuModal .menuContent .locations li {
  list-style: none;
  display: inline-block;
  margin-bottom: 4px;
  width: 50%;
}

#MenuModal .menuContent .locations li a {
  text-decoration: none;
  display: block;
  color: #ffffff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.9em;
  transition: 0.3s;
}

#MenuModal .menuContent .locations li a:hover {
  color: #2068ff;
}

#MenuModal .menuContent .footer {
  padding-top: 16px;
}

#MenuModal .menuContent .footer a {
  text-decoration: none;
  text-transform: uppercase;
  display: block;
  color: #ffffff;
  font-size: 0.9em;
  margin-bottom: 8px;
  transition: 0.3s;
}

#MenuModal .menuContent .footer a:hover {
  color: #2068ff;
}

#notificationBell {
  position: relative;
}

#notificationBell span {
  position: absolute;
  background-color: #2068ff;
  color: #ffffff;
  z-index: 3;
  left: 8px;
  top: 0px;
  border-radius: 90%;
  width: 12px;
  height: 12px;
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
  padding: 4px;

}

#SupportModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #00000033;
  height: calc(100% - 60px);
  z-index: 50;
}

#supportChat {
  position: relative;
  background-color: #000000;
  top: 72px;
  margin: 0 auto;
  max-width: 500px;
  height: calc(100% - 72px);
}

#supportChat section h3 {
  font-size: 1.2em;
  font-weight: bold;
}

#supportChat #chat {
  padding: 8px;
  position: absolute;
  top: 60px;
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 240px);
  overflow-y: scroll;
}

#supportChat #chatFooter {
  padding: 8px 8px 24px 8px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  box-sizing: border-box;
}

#supportChat #chatFooter .label {
  cursor: pointer;
}

#supportChat #chatFooter textarea,
#supportChat #chatFooter input {
  background-color: var(--main-bg-color);
  border: none;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 4px;
}

#supportChat #chatFooter textarea:focus {
  outline: none;
}

#supportChat #chat {

}

#supportChat #chat .label {
  display: block;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 8px 14px;
  margin-left: 16px;
  border-top-right-radius: 0px;
}

#supportChat #chat .label.blue {
  display: inline-block;
  background-color: #2068ff !important;
  margin-right: 16px;
  float: left;
  border-top-left-radius: 0px;
  border-top-right-radius: 16px;
}

#supportChat #chat div {
  float: none;
  clear: both;
}
</style>